<template>
  <div class="reportInter-pretation-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="预约类型:" prop="productId">
              <el-select clearable v-model="form.productId" placeholder="请选择预约类型">
                <el-option :label="obj.label" :value="obj.value" v-for="obj in productList"
                           :key="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码:">
              <el-input v-model="form.phone" :clearable="true" placeholder="请输入手机号码"
                        oninput="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="form.status" placeholder="请选择任务状态">
                <el-option :label="obj.label" :value="obj.value" v-for="obj in dynamicList"
                           :key="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预约日期" prop="date">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                clearable
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search(true)" icon="el-icon-search">查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="card" shadow="always">
      <div class="table-block">
        <el-table :data="tableData" ref="multipleTable" v-loading="loading"
                  :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}"
                  :cell-style="{'text-align':'center'}" style="width: 100%">
          <el-table-column prop="name" label="客户姓名"></el-table-column>
          <el-table-column prop="productName" label="预约类型"></el-table-column>
          <el-table-column prop="phone" label="手机号码"></el-table-column>
          <el-table-column prop="sampleboxNo" label="样本编号"></el-table-column>
          <el-table-column prop="employeeName" label="预约姓名"></el-table-column>
          <el-table-column prop="interpretTypeStr" label="解读方式"></el-table-column>
          <el-table-column prop="employeeDuty" label="岗位"></el-table-column>
          <el-table-column prop="statusStr" label="任务状态"></el-table-column>
          <el-table-column prop="taskStartTime" label="预约时间"></el-table-column>
          <el-table-column prop="finishTime" label="完成时间"></el-table-column>
          <el-table-column label="操作" fixed="right" width="190">
            <template slot-scope="scope">
              <el-button @click="viewReport(scope.row)" type="text" size="small">查看报告</el-button>
              <el-button v-if="scope.row.status === '17'" @click="handleClick(scope.row,true)" type="text" size="small">
                取消预约
              </el-button>
              <el-button v-if="scope.row.status === '17'" @click="handleClick(scope.row,false)" type="text"
                         size="small">完成解读
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="form.pageNo"
        :page-size="form.pageSize"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialogVisiblesee" width="40%" class="Visiblesee" fullscreen
               style="text-align: center; background-color: red" @close="onClose">
               <el-row>
                <el-col :span="2">
                  <div @click="()=> iframClick(item,index)" v-for="(item,index) in pdfList" :class="{'item_box':true,'active':activeIndex == index}" :key="index">{{ item }}}</div>
                </el-col>
                <el-col :span="22">
                <iframe :src="iframeSrc" alt="" frameborder="0" style="width: 95%; height: calc(100vh - 100px)" scrolling="no" />
                </el-col>
               </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getProduct, queryQuestionList, queryReport, updateTask } from "@/api/modules/miniProSentBack";

export default {
  components: {},
  data() {
    return {
      pdfList:[],
      form: {
        status: "",
        productId: "",
        phone: "",
        startTime: "",
        endTime: "",
        samplingType: "6",
        date: [],
        pageNo: 1,
        pageSize: 10
      },
      activeIndex:0,
      productList: [],
      tableData: [{}],
      total: null,
      loading: false,
      dialogVisiblesee: false,
      iframeSrc: "",
      dynamicList: [
        { label: "待解读", value: "17" },
        { label: "已解读", value: "18" },
        { label: "已取消", value: "99" }
      ]
    };
  },
  watch: {
    "form.date"(val) {
      if (val && val.length) {
        this.form.startTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    }
  },
  mounted() {
    this.search();
    this.getProductFn();
  },
  methods: {
    onClose(){
      this.activeIndex = 0
      this.dialogVisiblesee = false
    },
    iframClick(item,index){
      this.activeIndex = index;
      this.iframeSrc = item
    },
    handleClick(row, type) {
      const msg = type ? "确定取消预约吗？" : "确定完成解读吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning"
      }).then(() => {
        const data = type ? { id: row.id, subStatus: "9901" } : { id: row.id, subStatus: "1801" };
        updateTask(data).then(() => {
          this.$message({
            type: "success",
            message: type ? "取消预约成功" : "完成解读成功"
          });
          this.search();
        });
      }).catch(() => {
      });
    },
    getProductFn() {
      getProduct(2)
        .then(res => {
          const setKeyValue = (obj) => {
            let object = {};
            for (let key in obj) {
              object = {
                label: obj[key],
                value: key
              };
            }
            return object;
          };
          this.productList = res.map(item => {
            return setKeyValue(item);
          });
        })
        .catch(() => {
        });
    },
    closeDialog() {
      this.dialogProps.visible = false;
    },
    search(type) {
      if (type) this.form.pageNo = 1;
      this.loading = true;
      queryQuestionList(this.form)
        .then(res => {
          this.total = res.count;
          this.loading = false;
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewReport(row) {
      queryReport(row.mailingTaskId).then(res => {
        this.pdfList = res
        this.iframeSrc = res[0];
        this.dialogVisiblesee = true;
      });
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.reportInter-pretation-page {
  background: transparent;
}

.searchFrom > .el-form-item {
  margin-right: 30px !important;
}
.item_box{
  height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-spacing: normal;
    white-space: nowrap;
    cursor: pointer;
}
.active{
  border-bottom: 1px solid #5479FF;
}
.Selectnum {
  padding: 6px 30px;
  border: 1px solid #BFCBF4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
