import request from "src/api/request";
import requestPro from "src/api/request2";

export const queryQuestionList = data =>
  requestPro({
    method: "post",
    url: "/task/queryQuestionList",
    data
  });
export const updateCustomerInfo = data =>
  requestPro({
    method: "post",
    url: "/task/updateCustomerInfo",
    data
  });
export const taskUpload = data =>
  requestPro({
    method: "post",
    url: "/task/taskUpload",
    data
  });
export const updateTask = data =>
  requestPro({
    method: "post",
    url: "/task/updateTask",
    data
  });
export const queryTaskInfo = taskId =>
  requestPro({
    method: "get",
    url: `/task/queryTaskInfo/${taskId}`
  });
export const getProduct = (type) =>
  requestPro({
    method: "get",
    url: `/common/getProduct/${type}`
  });
export const confirmCustomer = (taskId) =>
  requestPro({
    method: "get",
    url: `/task/confirmCustomer/${taskId}`
  });
export const deleteTaskResult = (id) =>
  requestPro({
    method: "get",
    url: `/task/deleteTaskResult/${id}`
  });
export const queryQuestionAnswer = (questionAnswerId) =>
  requestPro({
    method: "get",
    url: `questionnaire/queryQuestionAnswer/${questionAnswerId}`
  });
export const queryReport = (mailingTaskId) =>
  requestPro({
    method: "get",
    url: `task/queryReport/${mailingTaskId}`
  });
